
import React, {useState, useEffect} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';
import Home from './Pages/Home';
import Portfolio from './Pages/Portfolio';
import ContactUs from './Pages/ContactUs';
import OurTeam from './Pages/OurTeam';

function App() {
  return (
    <div className="App">
     <BrowserRouter>
      <Routes >
        {/* Define your routes using <Route> components */}
        
        <Route path="/" element={<Home />} />
        <Route path="/Portfolio" element={<Portfolio />} />
        <Route path="/ourteam" element={<OurTeam />} />
        <Route path="/contactus" element={<ContactUs />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
