import React, { useState, useEffect } from 'react';
import Navbar from '../Components/Navbar';
import './Home.css';
import { Link } from 'react-router-dom';

const ContactUs = () => {
//   const [isLoading, setIsLoading] = useState(true);

//   // Simulating a delay to showcase the loading state
//   setTimeout(() => {
//     setIsLoading(false);
//   }, 0);

const [isMobile, setIsMobile] = useState(false);

useEffect(() => {
    // Function to check if the user is on a mobile device
    const checkIsMobile = () => {
      const mobileMediaQuery = window.matchMedia('(max-width: 767px)'); // Adjust the breakpoint to your preference
      setIsMobile(mobileMediaQuery.matches);
    };

    // Initial check
    checkIsMobile();

    // Add event listener for changes in screen size
    window.addEventListener('resize', checkIsMobile);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);


  return (
    <div
    
style={{ marginLeft:'5%',  marginRight:isMobile? '10%': '40%', backgroundColor:'black',marginTop:'-5vh', height:'105vh'}}
    >
 <h1 style={{textAlign:'left'}}></h1>
<div style={{height:'5vh'}}></div>
<Link to="/" style={{ textDecoration: 'none' }}>
     
     <h3>back</h3>
 
     </Link>
     <h1 style={{textAlign:'left'}}>contact us</h1>

     <div style={{textAlign:'left', background:'#333300' , border: '5px solid #663300', padding:'20px', textDecoration:''}}> 
        <a  style={{textDecoration:'none'}} target='_blank' href={'https://t.me/moxcapital'}>
            <h2>telegram: @moxcapital </h2></a>
            <a  style={{textDecoration:'none'}} target='_blank' href={'https://x.com/mox_capital'}>
            <h2>twitter: @mox_capital </h2></a>
            <h2>internships: contact@mox.capital</h2>

            <p style={{fontSize:'4vh'}}>7 Cloud City, Bespin</p>
       


{/* 
    <h1>jobs </h1> */}

{/* <a href=''>
        <p style={{ fontWeight: 'bold', textDecoration: 'underline'}}>to mox ventures</p>
        </a> */}

     </div>
     <div  style={{flexDirection:'row', display: 'flex', gap:'5vw', marginTop:'5vh'}}> 



     </div>
   
    </div>
  );
};

export default ContactUs;
