import React, { useState ,useEffect} from 'react';
import Navbar from '../Components/Navbar';
import './Home.css';
import { Link } from 'react-router-dom';

const OurTeam = () => {
//   const [isLoading, setIsLoading] = useState(true);

//   // Simulating a delay to showcase the loading state
//   setTimeout(() => {
//     setIsLoading(false);
//   }, 0);

const [isMobile, setIsMobile] = useState(false);

useEffect(() => {
    // Function to check if the user is on a mobile device
    const checkIsMobile = () => {
      const mobileMediaQuery = window.matchMedia('(max-width: 767px)'); // Adjust the breakpoint to your preference
      setIsMobile(mobileMediaQuery.matches);
    };

    // Initial check
    checkIsMobile();

    // Add event listener for changes in screen size
    window.addEventListener('resize', checkIsMobile);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);


  return (

    
    <div
    
style={{ marginLeft:'5%', marginRight:'40%', backgroundColor:'black',marginTop:'-5vh', height: isMobile? '150vh':'200vh'}}
    >
 <h1 style={{textAlign:'left'}}></h1>
<div style={{height:'5vh'}}></div>

     <h1 style={{textAlign:'left'}}>
     <Link to="/" style={{ textDecoration: 'none' }}>
     
     <h3>back</h3>
 
     </Link>our team</h1>

     <div style={{height:'2vh'}}></div>
     <h1>[ Partners ]</h1>
     
     <div  style={{flexDirection: isMobile ? 'column':'row', display: 'flex', gap:'5vw', marginTop:'5vh'}}> 

     <a href="https://twitter.com/m0xruby?s=11&t=vHfZIEc50vkOXgh2A8EJlA"  target="_blank" style={{ textDecoration: 'none' }}>
     <button style={{textAlign:'', background:'#333300' , border: '5px solid #663300', padding:'10px', alignItems:'center', width:'', minWidth:'200px'} }> 
    <h3>[moxRuby]</h3>
    <p style={{fontSize:'3vh'}}>Lvl 99 Wizard</p>
    <p style={{fontSize:'2vh'}}>Partner</p>
    </button>

    </a>

    <a href="https://twitter.com/moxJet_"  target="_blank" style={{ textDecoration: 'none' }}>
    <button style={{textAlign:'', background:'#333300' , border: '5px solid #663300', padding:'10px', alignItems:'center', width:'', minWidth:'200px'}}> 
    <h3>[moxJet]</h3>
    <p style={{fontSize:'3vh'}}>Party Cleric</p>
    <p style={{fontSize:'2vh'}}>Partner</p>
    </button>
    </a>

    {/* <a href="https://twitter.com/m0xEmerald?t=DTMC84SrY7hdyKxN6O0_tg&s=09"  target="_blank" style={{ textDecoration: 'none' }}>
    <button style={{textAlign:'', background:'#333300' , border: '5px solid #663300', padding:'10px', alignItems:'center', width:'', minWidth:'200px'}}> 
    <h3>[moxEmerald]</h3>
    <p style={{fontSize:'3vh'}}>Head Chef</p>
    <p style={{fontSize:'2vh'}}>Partner</p>
    </button>
    </a> */}


   
     </div>
{/* 
     <h1 style={{marginTop:'100px'}}>[ The Guild ]</h1>

      */}
     



    </div>
  );
};

export default OurTeam;
