import React, { useState , useEffect} from 'react';
import Navbar from '../Components/Navbar';
import './Home.css';
import { Link } from 'react-router-dom';
import './PreloadingPage.css'
import moxLogo from '../Assets/moxLogo.png'

const Home = () => {
    const [isLoading, setIsLoading] = useState(true); // Add isLoading state

//   const [isLoading, setIsLoading] = useState(true);

//   // Simulating a delay to showcase the loading state
//   setTimeout(() => {
//     setIsLoading(false);
//   }, 0);



const [isMobile, setIsMobile] = useState(false);

useEffect(() => {
    // Function to check if the user is on a mobile device
    const checkIsMobile = () => {
      const mobileMediaQuery = window.matchMedia('(max-width: 767px)'); // Adjust the breakpoint to your preference
      setIsMobile(mobileMediaQuery.matches);
    };

    // Initial check
    checkIsMobile();

    // Add event listener for changes in screen size
    window.addEventListener('resize', checkIsMobile);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 800); // Adjust the delay time as needed

    return () => clearTimeout(timer);
  }, []);


  return (
    <div
    
style={{ marginLeft:'5%', marginRight:isMobile? '10%': '40%', backgroundColor:'black',marginTop:'-5vh', height:'105vh'}}
    >

{isLoading ? ( // Render preloading content if isLoading is true
        <div className="preloading-container">

          <img src= {moxLogo} alt="Rotating Image" className="rotating-image" />




  
        </div>
      ) : ( // Render main content once isLoading is false
        <>
          <h1 style={{textAlign:'left'}}></h1>
          <div style={{height:'5vh'}}></div>
          <h1 style={{textAlign:'left'}}>mox capital</h1>

          <div style={{textAlign:'left', background:'#333300' , border: '5px solid #663300', padding:'20px'}}> 
        <h2>welcome to mox</h2>
        <p>
        mox is a private future forward think-tank aiming to promote growth for the next generation of creators & builders. 
        <br></br>        <br></br>
       
        With a focus on alternative investments, mox aims to provide the platform for the round pegs in square holes to grow.</p>
    <p style={{ fontWeight: 'bold', fontStyle: 'italic'}}>we like cool shit.</p>
    <p style={{ fontWeight: '', textDecoration: ''}}></p>
     </div>
     <div  style={{flexDirection:'row', display: 'flex', gap: isMobile?'3vw':'5vw', marginTop: isMobile? '3vw':'5vh', height:'', backgroundColor:''}}> 

     <Link to="/ourteam" style={{ textDecoration: 'none' }}>
     <button style={{textAlign:'', background:'#333300' , border: '5px solid #663300', padding:'', alignItems:'center', minWidth:'', height:'100%'} }> 
    
    <h3>[Our Team]</h3> 
    
    </button>
    </Link>



    <Link to="/portfolio" style={{ textDecoration: 'none' }}>
          <button style={{ textAlign: '', background: '#333300', border: '5px solid #663300', padding: '', alignItems: 'center', height:'100%' }}>
            <h3>[Portfolio]</h3>
          </button>
        </Link>


        <Link to="/contactus" style={{ textDecoration: 'none' }}>
    <button style={{textAlign:'', background:'#333300' , border: '5px solid #663300', padding:'', alignItems:'center', minWidth:'', height:'100%'}}> 
    <h3>[Contact Us]</h3>
    </button>
    </Link>
     </div>
   

        </>
      )}
    </div>
  );



    
  
};

export default Home;
